export default interface IAppVersion {
    id?: string;
    app: AppType;
    version: string;
    createdAt: number;
}

export enum AppType {
    None,
    Web,
    Mobile,
    BackOffice,
}
