import IInfo, {IInfoTranslation, InfoType} from "../../../../model/firestore/Info";
import {
    Grid,
    JsonInput,
    MultiSelect,
    Select,
    SelectItem,
    Switch,
    Textarea,
    TextInput,
    useComponentDefaultProps
} from "@mantine/core";
import {getFESupportedLanguagesOptions, tt} from "../../../../core/Localization";
import {useForm} from "@mantine/form";
import React, {useContext, useEffect, useState} from "react";
import AppModal from "../AppModal";
import {getInfoTypesForSelect, getMessageSeveritiesForSelect} from "../../../../service/InfoService";
import AppButton from "../../buttons/AppButton";
import OptionalElement from "../../form/OptionalElement";
import {AppDataContext} from "../../../../AppData";
import FormCategoryTitle from "../../form/FormCategoryTitle";
import {DateTimePicker, DateValue} from "@mantine/dates";
import {DateTime} from "luxon";
import {AppType} from "../../../../model/firestore/AppVersion";
import {appTypeToOptions} from "../../../../service/AppVersionService";

export interface IInfoEditModalProps {
    opened: boolean;
    close: () => void;
    existing?: IInfo | NullOrUndefined;
    onSubmit: (info: IInfo) => void;
    loading?: boolean;
}

const defaultProps: Partial<IInfoEditModalProps> = {
    loading: false,
};

export interface IInfoFormValues {
    apps: string[];
    languages: string[];
    type: string;
    title: string;
    text?: string;
    messageSeverity?: string | NullOrUndefined;
    appVersions?: string[];
    from?: DateValue;
    to?: DateValue;
    enabled: boolean;
    debugOnly: boolean;
    authUsersOnly: boolean;
    targetUrl?: string;
    jsonPayload?: string;
}

/**
 * Modal component for editing Info or create new.
 */
export default function InfoEditModal(props: IInfoEditModalProps) {
    const {opened, close, existing, onSubmit, loading} = useComponentDefaultProps('InfoEditModal', defaultProps, props);

    const appDataContext = useContext(AppDataContext);
    const {theme} = appDataContext;

    const [selectedLanguage, setSelectedLanguage] = useState<string | null>();
    const [translations, setTranslations] = useState<IInfoTranslation[]>([]);
    const [selectedTranslation, setSelectedTranslation] = useState<IInfoTranslation | NullOrUndefined>(null);
    const [appVersionsData, setAppVersionsData] = useState<SelectItem[]>([]);
    const [checkElementValue, setCheckElementValue] = useState<number>(0);

    const title = existing ? tt('infoEditModal.title.edit') : tt('infoEditModal.title.create');

    const form = useForm<IInfoFormValues>({
        initialValues: {
            apps: existing && existing.apps ? existing.apps.map(app => app.toString()) : [],
            type: existing ? existing.type.toString() : InfoType.TextOnly.toString(),
            title: existing ? existing.title : '',
            text: existing?.text || '',
            messageSeverity: existing && existing.messageSeverity ? existing.messageSeverity.toString() : null,
            languages: existing ? existing.languages : [],
            appVersions: existing && existing.appVersions ? existing.appVersions : [],
            from: existing && existing.from ? DateTime.fromMillis(existing.from).toJSDate() : undefined,
            to: existing && existing.to ? DateTime.fromMillis(existing.to).toJSDate() : undefined,
            enabled: existing ? existing.enabled : true,
            debugOnly: existing ? existing.debugOnly : false,
            authUsersOnly: existing ? existing.authUsersOnly || false : false,
            targetUrl: existing?.targetUrl || '',
            jsonPayload: existing?.jsonPayload || '',
        },
        validate: {
            apps: (value) => value.length > 0 ? null : tt('error.isRequired'),
            type: (value) => value && parseInt(value) !== InfoType.None ? null : tt('error.isRequired'),
            title: (value) => value ? null : tt('error.isRequired'),
            text: (value) => null,
            messageSeverity: (value) => null,
            languages: (value) => null,
            appVersions: (value) => null,
            from: (value) => {
                if (!value) {
                    return null;
                }

                if (form.values.to && DateTime.fromJSDate(form.values.to).toMillis() < DateTime.fromJSDate(value).toMillis()) {
                    return tt('error.invalidDateRange');
                }

                return null;
            },
            to: (value) => {
                if (!value) {
                    return null;
                }

                if (form.values.from && DateTime.fromJSDate(form.values.from).toMillis() > DateTime.fromJSDate(value).toMillis()) {
                    return tt('error.invalidDateRange');
                }

                return null;
            },
            enabled: (value) => null,
            debugOnly: (value) => null,
            authUsersOnly: (value) => null,
            targetUrl: (value) => null,//!value || isValidUrl(value) ? null : tt('error.invalidUrl'),
            jsonPayload: (value) => null,
        },
    });

    useEffect(() => {
        if (opened) {
            form.setValues({
                apps: existing && existing.apps ? existing.apps.map(app => app.toString()) : [],
                type: existing ? existing.type.toString() : InfoType.TextOnly.toString(),
                title: existing ? existing.title : '',
                text: existing?.text || '',
                messageSeverity: existing && existing.messageSeverity ? existing.messageSeverity.toString() : null,
                languages: existing ? existing.languages : [],
                appVersions: existing && existing.appVersions ? existing.appVersions : [],
                from: existing && existing.from ? DateTime.fromMillis(existing.from).toJSDate() : undefined,
                to: existing && existing.to ? DateTime.fromMillis(existing.to).toJSDate() : undefined,
                enabled: existing ? existing.enabled : true,
                debugOnly: existing ? existing.debugOnly : false,
                authUsersOnly: existing ? existing.authUsersOnly || false : false,
                targetUrl: existing?.targetUrl || '',
                jsonPayload: existing?.jsonPayload || '',
            });

            setTranslations(existing ? existing.translations : []);

            setAppVersionsData(existing && existing.appVersions ? existing.appVersions.map((version) => ({
                value: version,
                label: version
            })) : []);

            setCheckElementValue(checkElementValue + 1);
        }
    }, [opened]);

    /**
     * OnSubmit process values and send to callback.
     */
    const onSubmitProcess = (values: IInfoFormValues) => {
        const info: IInfo = {
            ...existing,
            apps: values.apps.map((app) => parseInt(app) as AppType),
            type: parseInt(values.type),
            title: values.title,
            titleSearchable: values.title.toLowerCase(),
            text: values.text || null,
            messageSeverity: values.messageSeverity ? parseInt(values.messageSeverity) : null,
            languages: values.languages,
            translations: translations.filter((translation) => values.languages.includes(translation.language)),
            appVersions: values.appVersions || null,
            from: values.from ? DateTime.fromJSDate(values.from).toMillis() : null,
            to: values.to ? DateTime.fromJSDate(values.to).toMillis() : null,
            enabled: values.enabled,
            debugOnly: values.debugOnly,
            authUsersOnly: values.authUsersOnly,
            targetUrl: values.targetUrl || null,
            jsonPayload: values.jsonPayload || null,
            updatedAt: DateTime.local().toMillis(),
            createdAt: existing ? existing.createdAt : DateTime.local().toMillis(),
        };

        onSubmit(info);
    };

    useEffect(() => {
        if (selectedLanguage && !form.values.languages.includes(selectedLanguage)) {
            setSelectedLanguage(null);
        }
    }, [form.values.languages, selectedLanguage]);

    useEffect(() => {
        for (const language of form.values.languages) {
            if (!translations.find((translation) => translation.language === language)) {
                setTranslations([
                    ...translations,
                    {
                        language,
                        title: '',
                        text: '',
                    },
                ]);
            }
        }
    }, [form.values.languages, translations]);

    useEffect(() => {
        if (selectedLanguage) {
            setSelectedTranslation(translations.find((translation) => translation.language === selectedLanguage));
        } else if (selectedTranslation && !selectedLanguage) {
            setSelectedTranslation(null);
        }
    }, [selectedLanguage, translations, selectedTranslation]);

    /**
     * Update field of selected translation.
     */
    const updateSelectedTranslationField = (field: string, value: string) => {
        if (selectedTranslation) {
            const updatedTranslations = translations.map((translation) => {
                if (translation.language === selectedTranslation.language) {
                    return {
                        ...translation,
                        [field]: value,
                    };
                }

                return translation;
            });

            setTranslations(updatedTranslations);

            setSelectedTranslation(updatedTranslations.find((translation) => translation.language === selectedTranslation.language));
        }
    };

    const translationsFormFieldsJSX = form.values.languages.length > 0 ? (
        <>
            <Grid columns={theme.isMobile ? 6 : 12}>
                <Grid.Col span={6}>
                    <MultiSelect
                        data={getFESupportedLanguagesOptions()}
                        label={tt('infoEditModal.languages.label')}
                        placeholder={tt('infoEditModal.languages.placeholder')}
                        mb="sm"
                        {...form.getInputProps('languages')}
                    />
                </Grid.Col>

                <Grid.Col span={6}>
                    <Select
                        data={getFESupportedLanguagesOptions().filter((option) => form.values.languages.includes(option.value))}
                        label={tt('infoEditModal.selectedLanguage.label')}
                        placeholder={tt('infoEditModal.selectedLanguage.placeholder')}
                        mb="sm"
                        value={selectedLanguage}
                        onChange={setSelectedLanguage}
                    />
                </Grid.Col>
            </Grid>

            {selectedTranslation ? (
                <>
                    <TextInput
                        label={tt('infoEditModal.title.label')}
                        placeholder={tt('infoEditModal.title.placeholder')}
                        mb="sm"
                        value={selectedTranslation.title}
                        onChange={(event) => updateSelectedTranslationField('title', event.currentTarget.value)}
                    />

                    <OptionalElement
                        text={tt('infoEditModal.text.optional')}
                        elementValue={selectedTranslation.text || form.values.text}
                        checkElementValue={checkElementValue}
                    >
                        <Textarea
                            label={tt('infoEditModal.text.label')}
                            placeholder={tt('infoEditModal.text.placeholder')}
                            mb="sm"
                            value={selectedTranslation.text}
                            onChange={(event) => updateSelectedTranslationField('text', event.currentTarget.value)}
                        />
                    </OptionalElement>
                </>
            ) : undefined}
        </>
    ) : (
        <MultiSelect
            data={getFESupportedLanguagesOptions()}
            label={tt('infoEditModal.languages.label')}
            placeholder={tt('infoEditModal.languages.placeholder')}
            mb="sm"
            {...form.getInputProps('languages')}
        />
    );

    return (
        <AppModal
            size="lg"
            opened={opened}
            close={close}
            title={title}
        >
            <form
                onSubmit={form.onSubmit((values) => onSubmitProcess(values))}
            >
                <MultiSelect
                    data={appTypeToOptions(true)}
                    label={tt('infoEditModal.apps.label')}
                    placeholder={tt('infoEditModal.apps.placeholder')}
                    mb="sm"
                    {...form.getInputProps('apps')}
                />

                <Grid columns={theme.isMobile ? 6 : 12}>
                    <Grid.Col span={6}>
                        <Select
                            data={getInfoTypesForSelect()}
                            label={tt('infoEditModal.type.label')}
                            mb="sm"
                            {...form.getInputProps('type')}
                        />
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <TextInput
                            label={tt('infoEditModal.title.label')}
                            placeholder={tt('infoEditModal.title.placeholder')}
                            mb="sm"
                            {...form.getInputProps('title')}
                        />
                    </Grid.Col>
                </Grid>

                <OptionalElement
                    text={tt('infoEditModal.text.optional')}
                    elementValue={form.values.text}
                    checkElementValue={checkElementValue}
                >
                    <Textarea
                        label={tt('infoEditModal.text.label')}
                        placeholder={tt('infoEditModal.text.placeholder')}
                        mb="sm"
                        {...form.getInputProps('text')}
                    />
                </OptionalElement>

                <OptionalElement
                    text={tt('infoEditModal.messageSeverity.optional')}
                    elementValue={form.values.messageSeverity}
                    checkElementValue={checkElementValue}
                >
                    <Select
                        label={tt('infoEditModal.messageSeverity.label')}
                        placeholder={tt('infoEditModal.messageSeverity.placeholder')}
                        mb="sm"
                        data={getMessageSeveritiesForSelect()}
                        {...form.getInputProps('messageSeverity')}
                    />
                </OptionalElement>

                <FormCategoryTitle
                    title={tt('infoEditModal.translations.title')}
                    styleProps={{mb: 'sm'}}
                />

                {translationsFormFieldsJSX}

                <FormCategoryTitle
                    title={tt('infoEditModal.conditions.title')}
                    styleProps={{mb: 'sm'}}
                />

                <OptionalElement
                    text={tt('infoEditModal.appVersions.optional')}
                    elementValue={form.values.appVersions && form.values.appVersions.length > 0}
                    checkElementValue={checkElementValue}
                >
                    <MultiSelect
                        label={tt('infoEditModal.appVersions.label')}
                        placeholder={tt('infoEditModal.appVersions.placeholder')}
                        mb="sm"
                        data={appVersionsData}
                        searchable={true}
                        creatable={true}
                        getCreateLabel={(query) => tt('infoEditModal.appVersions.create.selectItem').replace('{query}', query)}
                        onCreate={(query) => {
                            const item = {value: query, label: query};
                            setAppVersionsData((current) => [...current, item]);
                            return item;
                        }}
                        {...form.getInputProps('appVersions')}
                    />
                </OptionalElement>

                <OptionalElement
                    text={tt('infoEditModal.from.optional')}
                    elementValue={form.values.from}
                    checkElementValue={checkElementValue}
                >
                    <DateTimePicker
                        dropdownType="modal"
                        label={tt('infoEditModal.from.label')}
                        placeholder={tt('infoEditModal.from.placeholder')}
                        mb="sm"
                        maxDate={form.values.to ? form.values.to : undefined}
                        {...form.getInputProps('from')}
                    />
                </OptionalElement>

                <OptionalElement
                    text={tt('infoEditModal.to.optional')}
                    elementValue={form.values.to}
                    checkElementValue={checkElementValue}
                >
                    <DateTimePicker
                        dropdownType="modal"
                        label={tt('infoEditModal.to.label')}
                        placeholder={tt('infoEditModal.to.placeholder')}
                        mb="sm"
                        minDate={form.values.from ? form.values.from : undefined}
                        {...form.getInputProps('to')}
                    />
                </OptionalElement>

                <Switch
                    label={tt('infoEditModal.enabled.label')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.enabled}
                    {...form.getInputProps('enabled')}
                />

                <Switch
                    label={tt('infoEditModal.debugOnly.label')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.debugOnly}
                    {...form.getInputProps('debugOnly')}
                />

                <Switch
                    label={tt('infoEditModal.authUsersOnly.label')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.authUsersOnly}
                    {...form.getInputProps('authUsersOnly')}
                />

                <FormCategoryTitle
                    title={tt('infoEditModal.actions.title')}
                    styleProps={{mb: 'sm'}}
                />

                <OptionalElement
                    text={tt('infoEditModal.targetUrl.optional')}
                    elementValue={form.values.targetUrl}
                    checkElementValue={checkElementValue}
                >
                    <TextInput
                        label={tt('infoEditModal.targetUrl.label')}
                        placeholder={tt('infoEditModal.targetUrl.placeholder')}
                        mb="sm"
                        {...form.getInputProps('targetUrl')}
                    />
                </OptionalElement>

                <OptionalElement
                    text={tt('infoEditModal.jsonPayload.optional')}
                    elementValue={form.values.jsonPayload}
                    checkElementValue={checkElementValue}
                >
                    <JsonInput
                        label={tt('infoEditModal.jsonPayload.label')}
                        placeholder={tt('infoEditModal.jsonPayload.placeholder')}
                        validationError={tt('error.invalidJson')}
                        mb="sm"
                        formatOnBlur={true}
                        autosize={true}
                        minRows={4}
                        {...form.getInputProps('jsonPayload')}
                    />
                </OptionalElement>

                <AppButton
                    loading={loading}
                    type="submit"
                    fullWidth={true}
                    mb="sm"
                >
                    {tt('common.submit')}
                </AppButton>
            </form>
        </AppModal>
    );
}
