import {
    FacebookAuthProvider,
    getAuth,
    getRedirectResult,
    GoogleAuthProvider,
    OAuthProvider, signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect
} from "firebase/auth";
import {DateTime} from "luxon";
import {LoginProvider} from "../generated/graphql/graphql";
import {IAppDataContext} from "../AppData";
import {tt} from "../core/Localization";
import {ErrorToast} from "./ToastService";
import {ISignInFormValues} from "../ui/screens/authorization/SignInScreen";

export const kStorageAutoSignInRefresh = 'authUser_auto_refresh';
export const kMinutesBetweenAutoSignInRefresh = 10;

/**
 * SignIn with email and password.
 * SignIn with socials using redirect method.
 */
export async function AuthUserSignIn(
    appDataContext: IAppDataContext,
    provider: LoginProvider,
    values?: ISignInFormValues,
) {
    const {language} = appDataContext;

    const auth = getAuth();
    auth.languageCode = language;

    try {
        if (provider === LoginProvider.Email && values) {
            await signInWithEmailAndPassword(
                getAuth(),
                values.email,
                values.password,
            );
        }

        if (provider === LoginProvider.Google) {
            const theProvider = new GoogleAuthProvider();
            theProvider.addScope('email');
            theProvider.addScope('profile');

            await signInWithRedirect(auth, theProvider);

            // const result = await signInWithPopup(auth, theProvider);
        }

        if (provider === LoginProvider.Facebook) {
            const theProvider = new FacebookAuthProvider();
            theProvider.addScope('email');
            theProvider.addScope('public_profile');

            await signInWithRedirect(auth, theProvider);
        }

        if (provider === LoginProvider.Apple) {
            const theProvider = new OAuthProvider('apple.com');
            theProvider.addScope('email');
            theProvider.addScope('name');

            theProvider.setCustomParameters({
                locale: language
            });

            await signInWithRedirect(auth, theProvider);
        }
    } catch (e) {
        console.error(e);

        FirebaseAuthErrorMessage(e);
    }
}

/**
 * SignIn with socials process result from redirect method.
 */
export async function AuthUserSignInSocialRedirectResult() {
    try {
        const authResult = await getRedirectResult(getAuth());

        if (authResult) {
            // do nothing
        }
    } catch (e) {
        console.error(e);

        FirebaseAuthErrorMessage(e);
    }
}

/**
 * SignOut current user from Firebase.
 */
export async function AuthUserSignOut(appDataContext: IAppDataContext) {
    const {setAuth} = appDataContext;

    try {
        await getAuth().signOut();

        setAuth({authUser: null});
    } catch (e) {
        console.error(e);
    }
}

/**
 * Check if should refresh idToken by date.
 */
export function AuthUserCanRefreshToken(): boolean {
    const storedDate = localStorage.getItem(kStorageAutoSignInRefresh);

    if (storedDate) {
        const refreshedAt = DateTime.fromMillis(parseFloat(storedDate));

        const diff = DateTime.now().diff(refreshedAt, ['minutes']);

        if (diff.minutes < kMinutesBetweenAutoSignInRefresh) {
            return false;
        }
    }

    return true;
}

/**
 * Store idToken refresh date.
 */
export function AuthUserRefreshedToken() {
    localStorage.setItem(kStorageAutoSignInRefresh, `${DateTime.now().toMillis()}`);
}

/**
 * Convert providerId from Firebase to our Enum.
 */
export function FirebaseProviderToEnum(providerId: string): LoginProvider {
    switch (providerId) {
        case 'password':
            return LoginProvider.Email;
        case 'google.com':
            return LoginProvider.Google;
        case 'facebook.com':
            return LoginProvider.Facebook;
        case 'apple.com':
            return LoginProvider.Apple;
        default:
            throw Error(`Not supported Firebase providerId(${providerId})`);
    }
}

/**
 * Display Firebase auth error.
 */
export function FirebaseAuthErrorMessage(e: any | undefined) {
    if (!e || !e.code) {
        ErrorToast(tt('firebaseAuth.error'));
    }

    if (e.code === 'auth/email-already-in-use') {
        ErrorToast(tt('firebaseAuth.error.emailUsed'));
    } else if (e.code === 'auth/invalid-email') {
        ErrorToast(tt('firebaseAuth.error.invalidEmail'));
    } else if (e.code === 'auth/weak-password') {
        ErrorToast(tt('firebaseAuth.error.weakPassword'));
    } else if (e.code === 'auth/wrong-password') {
        ErrorToast(tt('firebaseAuth.error.wrongPassword'));
    } else if (e.code === 'auth/account-exists-with-different-credential') {
        ErrorToast(tt('firebaseAuth.error.anotherProvider'));
    } else {
        ErrorToast(tt('firebaseAuth.error'));
    }
}
