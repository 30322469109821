export default class Debouncer {
    milliseconds: number;
    timeout: NodeJS.Timeout | undefined;

    /**
     * Debouncer initializes with a given number of milliseconds.
     */
    constructor(milliseconds: number) {
        this.milliseconds = milliseconds;
    }

    /**
     * Debouncer dispose timer.
     */
    dispose() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    /**
     * Debounce will execute the given function after the given number of milliseconds.
     */
    run(fn: () => void, milliseconds?: number) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(fn, milliseconds || this.milliseconds);
    }
}
