import {AppType} from "./AppVersion";

export default interface IInfo {
    id?: string;
    apps: AppType[];
    type: InfoType;
    languages: string[];
    translations: IInfoTranslation[];
    title: string;
    titleSearchable: string;
    text?: string | NullOrUndefined;
    messageSeverity?: MessageSeverity | NullOrUndefined;
    appVersions?: string[] | NullOrUndefined;
    enabled: boolean;
    debugOnly: boolean;
    authUsersOnly?: boolean;
    from?: number | NullOrUndefined;
    to?: number | NullOrUndefined;
    targetUrl?: string | NullOrUndefined;
    jsonPayload?: string | NullOrUndefined;
    updatedAt: number;
    createdAt: number;
}

export enum InfoType {
    None,
    TextOnly,
}

export interface IInfoTranslation {
    language: string;
    title: string;
    text?: string;
}

export enum MessageSeverity {
    None,
    Success,
    Error,
    Info,
    Warning,
}
