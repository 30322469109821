export const kThemeAnimationDuration = 200;

export const kTopicUsers = "users"
export const kTopicClients = "clients"
export const kTopicClientContacts = "client_contacts"
export const kTopicCompanies = "companies"
export const kTopicSubscriptions = "subscriptions"
export const kTopicCompanyEmployees = "company_employees"
export const kTopicJobFormTemplates = "job_form_templates"
export const kTopicJobs = "jobs"
export const kTopicVisits = "visits"
export const kTopicJobProtocols = 'job_protocols'
export const kTopicLocations = "locations"
export const kTopicLocationPlaces = "location_places"
export const kTopicProductTemplates = "product_templates"
export const kTopicProducts = "products"
export const kTopicMaterialTemplates = "material_templates"
export const kTopicMaterials = "materials"
export const kTopicTimotySettings = "timoty_settings"

export const kActionView = "view"
export const kActionCreate = "create"
export const kActionUpdate = "update"
export const kActionDelete = "delete"

export const kTimotySettingsCreateCompanyEnabled = "create_company_enabled"
export const kTimotySettingsDeleteCompanyEnabled = "delete_company_enabled"
export const kTimotySettingsCloseAccountEnabled = "close_account_enabled"
export const kTimotySettingsAdminEmails = "admin_emails"
export const kTimotySettingsAdminUsers = "admin_users"
