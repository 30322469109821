import {MultiSelect, SelectItem, Switch, useComponentDefaultProps} from "@mantine/core";
import AppModal from "../AppModal";
import {tt} from "../../../../core/Localization";
import {useForm, UseFormReturnType} from "@mantine/form";
import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../../AppData";
import {
    kTimotySettingsAdminEmails,
    kTimotySettingsCreateCompanyEnabled,
    kTimotySettingsAdminUsers, kTimotySettingsDeleteCompanyEnabled, kTimotySettingsCloseAccountEnabled
} from "../../../../core/constants";
import AppButton from "../../buttons/AppButton";
import {
    AdminGetUsersInput,
    AdminUserResponsePage,
    SaveTimotySettingsItemsForKeysInputItemInput, UserResponse
} from "../../../../generated/graphql/graphql";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {processQueryError} from "../../../../service/ErrorService";
import {userFullName} from "../../../../service/UserService";

export interface ITimotyNotificationsSettingsModalProps {
    opened: boolean;
    close: () => void;
    onSubmit: (data: SaveTimotySettingsItemsForKeysInputItemInput[]) => void;
    loading?: boolean;
}

const defaultProps: Partial<ITimotyNotificationsSettingsModalProps> = {
    loading: false,
};

export interface ITimotyNotificationsSettingsFormValues {
    createCompanyEnabled: boolean;
    deleteCompanyEnabled: boolean;
    closeAccountEnabled: boolean;
    adminEmails: string[];
    adminUsers: string[];
}

/**
 * Modal component to edit Timoty notifications settings.
 */
export default function TimotyNotificationsSettingsModal(props: ITimotyNotificationsSettingsModalProps) {
    const {
        opened,
        close,
        onSubmit,
        loading
    } = useComponentDefaultProps('TimotyNotificationsSettingsModal', defaultProps, props);

    const appDataContext = useContext(AppDataContext);
    const {timotySettings} = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const [adminEmailsData, setAdminEmailsData] = useState<SelectItem[]>([]);
    const [usersForSelectedAdminUsers, setUsersForSelectedAdminUsers] = useState<UserResponse[]>([]);
    const [usersForQueryAdminUsers, setUsersForQueryAdminUsers] = useState<UserResponse[]>([]);
    const [adminUsersData, setAdminUsersData] = useState<SelectItem[]>([]);

    const form: UseFormReturnType<ITimotyNotificationsSettingsFormValues, (values: ITimotyNotificationsSettingsFormValues) => ITimotyNotificationsSettingsFormValues> = useForm<ITimotyNotificationsSettingsFormValues>({
        initialValues: {
            createCompanyEnabled: false,
            deleteCompanyEnabled: false,
            closeAccountEnabled: false,
            adminEmails: [],
            adminUsers: [],
        },
        validate: {
            createCompanyEnabled: (value) => null,
            deleteCompanyEnabled: (value) => null,
            closeAccountEnabled: (value) => null,
            adminEmails: (value) => {
                return value.length > 0 || !anyNotificationsEnabled(form.values) || form.values.adminUsers.length > 0 ? null : tt('error.isRequired');
            },
            adminUsers: (value) => {
                return value.length > 0 || !anyNotificationsEnabled(form.values) || form.values.adminEmails.length > 0 ? null : tt('error.isRequired');
            },
        },
    });

    useEffect(() => {
        if (opened) {
            const createCompanyEnabled = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsCreateCompanyEnabled);
            const deleteCompanyEnabled = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsDeleteCompanyEnabled);
            const closeAccountEnabled = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsCloseAccountEnabled);
            const adminEmails = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsAdminEmails);
            const adminUsers = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsAdminUsers);

            form.setValues({
                createCompanyEnabled: createCompanyEnabled ? (createCompanyEnabled.valueBool || false) : false,
                deleteCompanyEnabled: deleteCompanyEnabled ? (deleteCompanyEnabled.valueBool || false) : false,
                closeAccountEnabled: closeAccountEnabled ? (closeAccountEnabled.valueBool || false) : false,
                adminEmails: adminEmails && adminEmails.valueJsonJSON ? JSON.parse(adminEmails.valueJsonJSON).adminEmails || [] : [],
                adminUsers: adminUsers && adminUsers.valueJsonJSON ? JSON.parse(adminUsers.valueJsonJSON).adminUsers || [] : [],
            });

            if (adminEmails && adminEmails.valueJsonJSON) {
                setAdminEmailsData(JSON.parse(adminEmails.valueJsonJSON).adminEmails?.map((email: string) => ({value: email, label: email})) || []);
            } else {
                setAdminEmailsData([]);
            }
        }
    }, [opened]);

    useEffect(() => {
        if (opened) {
            setAdminUsersData([
                ...usersForSelectedAdminUsers.map((user: UserResponse) => ({value: user.id.toString(), label: `${userFullName(user.name, user.surname)} (${user.email || user.contactEmail || tt('common.error.noEmail')})`})),
                ...usersForQueryAdminUsers
                    .filter((user: UserResponse) => !usersForSelectedAdminUsers.includes(user))
                    .map((user: UserResponse) => ({value: user.id.toString(), label: `${userFullName(user.name, user.surname)} (${user.email || user.contactEmail || tt('common.error.noEmail')})`})),
            ]);
        }
    }, [opened, usersForSelectedAdminUsers, usersForQueryAdminUsers]);

    useEffect(() => {
        if (opened) {
            loadUsersForSelected(form.values.adminUsers?.map(userId => parseInt(userId)) || []);
        }
    }, [opened, form.values.adminUsers]);

    /**
     * Load Users by selected from BE.
     */
    const loadUsersForSelected = (userIds: number[]) => {
        if (userIds.length > 0) {
            restApiGet({
                uri: '/admin/user/search',
                params: {
                    userIds,
                } as AdminGetUsersInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                onData: (data: AdminUserResponsePage) => {
                    if (data) {
                        setUsersForSelectedAdminUsers(data.content);
                    } else {
                        setUsersForSelectedAdminUsers([]);
                    }
                },
                onError: (error) => processQueryError(appDataContext, error),
            });
        } else {
            setUsersForSelectedAdminUsers([]);
        }
    };

    /**
     * When search of Users changes, load Users by query from BE.
     */
    const loadUsersForQuery = (query: string) => {
        if (query.trim().length > 0) {
            restApiGet({
                uri: '/admin/user/search',
                params: {
                    search: query,
                    page: 0,
                    pageSize: 100,
                } as AdminGetUsersInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                onData: (data: AdminUserResponsePage) => {
                    if (data) {
                        setUsersForQueryAdminUsers(data.content);
                    } else {
                        setUsersForQueryAdminUsers([]);
                    }
                },
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setUsersForQueryAdminUsers([]);
        }
    };

    /**
     * OnSubmit process values and send to callback.
     */
    const onSubmitProcess = (values: ITimotyNotificationsSettingsFormValues) => {
        onSubmit([
            {
                key: kTimotySettingsCreateCompanyEnabled,
                valueBool: values.createCompanyEnabled || false,
            },
            {
                key: kTimotySettingsDeleteCompanyEnabled,
                valueBool: values.deleteCompanyEnabled || false,
            },
            {
                key: kTimotySettingsCloseAccountEnabled,
                valueBool: values.closeAccountEnabled || false,
            },
            {
                key: kTimotySettingsAdminEmails,
                valueJsonJSON: JSON.stringify({
                    adminEmails: values.adminEmails || [],
                }),
            },
            {
                key: kTimotySettingsAdminUsers,
                valueJsonJSON: JSON.stringify({
                    adminUsers: values.adminUsers || [],
                }),
            },
        ]);
    };

    return (
        <AppModal
            size="lg"
            opened={opened}
            close={close}
            title={tt('timotyNotificationsSettings.modal.title')}
        >
            <form
                onSubmit={form.onSubmit((values) => onSubmitProcess(values))}
            >
                <Switch
                    label={tt('timotyNotificationsSettings.modal.createCompanyEnabled')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.createCompanyEnabled}
                    {...form.getInputProps('createCompanyEnabled')}
                />
                <Switch
                    label={tt('timotyNotificationsSettings.modal.deleteCompanyEnabled')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.deleteCompanyEnabled}
                    {...form.getInputProps('deleteCompanyEnabled')}
                />
                <Switch
                    label={tt('timotyNotificationsSettings.modal.closeAccountEnabled')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.closeAccountEnabled}
                    {...form.getInputProps('closeAccountEnabled')}
                />

                {anyNotificationsEnabled(form.values) ? (
                    <>
                        <MultiSelect
                            label={tt('timotyNotificationsSettings.modal.adminUsers')}
                            placeholder={tt('timotyNotificationsSettings.modal.adminUsers.placeholder')}
                            mb="sm"
                            data={adminUsersData}
                            searchable={true}
                            creatable={false}
                            onSearchChange={loadUsersForQuery}
                            withinPortal={true}
                            {...form.getInputProps('adminUsers')}
                        />

                        <MultiSelect
                            label={tt('timotyNotificationsSettings.modal.adminEmails')}
                            placeholder={tt('timotyNotificationsSettings.modal.adminEmails.placeholder')}
                            mb="sm"
                            data={adminEmailsData}
                            searchable={true}
                            creatable={true}
                            getCreateLabel={(query) => tt('timotyNotificationsSettings.modal.adminEmails.create.selectItem').replace('{query}', query)}
                            onCreate={(query) => {
                                const item = {value: query, label: query};
                                setAdminEmailsData((current) => [...current, item]);
                                return item;
                            }}
                            {...form.getInputProps('adminEmails')}
                        />
                    </>
                ) : null}

                <AppButton
                    loading={loading}
                    type="submit"
                    fullWidth={true}
                    mb="sm"
                >
                    {tt('common.submit')}
                </AppButton>
            </form>
        </AppModal>
    );
}

/**
 * Check if any notifications are enabled.
 */
function anyNotificationsEnabled(values: ITimotyNotificationsSettingsFormValues): boolean {
    return values.createCompanyEnabled
        || values.deleteCompanyEnabled
        || values.closeAccountEnabled;
}
